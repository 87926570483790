import React from 'react';

export default function SurveyForm (){
  //const[showPage, setShowPage] = useState('trombones')

  return(
    <div id='survey-form'>
      <h1 id="title">freeCodeCamp Survey Form</h1>
      <p id="description">Thank you for taking the time to help us improve the platform</p>
      <form id="survey-form">
        <fieldset>
          <label htmlFor="name" id="name-label" >Name<input id="name" name="name" type="text" required placeholder="Enter your name" /></label>
          <label htmlFor="email" id="email-label">Email<input id="email" name="email" type="email" required placeholder="Enter your Email" /></label>
          <label htmlFor="number" id="number-label">Age (optional)<input id="number" name="number" type="number" min="13" max="120" placeholder="Age" /></label>
          <label htmlFor="dropdown">Which option best describes your current role
            <select id="dropdown" name="dropdown" >
              <option value="">Select current role </option>
              <option value="1">Software Developer</option>
              <option value="2">Mechanical Engineer</option>
            </select>
          </label>
          <label htmlFor="recommend">Would you recommend freeCodeCamp to a friend?</label>
          <label htmlFor="definitely"><input type="radio" id ="definitely" name="recommend" value="0" className="inline" />Definitely</label>
          <label htmlFor="maybe"><input type ="radio" id="maybe" name="recommend" value="1" className="inline" />Maybe</label>
          <label htmlFor="notsure" ><input id="notsure" type="radio" value="2" name="recommend" className="inline" />Not Sure</label>
          
          <label htmlFor="dropdowntwo">What is your favorite feature of freeCodeCamp?</label>
            <select id="dropdowntwo" name="dropdowntwo">
              <option value="">Select an option</option>
              <option value="1">Challenges</option>
              <option value="2">Projects</option>
              <option value="3">Community</option>
              <option value="4">Open Source</option>
            </select>

          <label>What would you like to see improved? (Check all that apply) </label>
          <label></label>
          <label><input type="checkbox" value="0" className="inline" />Front-end Projects</label>
          <label><input type="checkbox" value="1" className="inline" />Back-end Projects</label>
          <label><input type="checkbox" value="2" className="inline" />Data Visualization</label>
          <label><input type="checkbox" value="3" className="inline" />Challenges</label>
          <label><input type="checkbox" value="4" className="inline" />Open Source Community</label>
          <label><input type="checkbox" value="5" className="inline" />Gitter help rooms</label>
          <label><input type="checkbox" value="6" className="inline" />Videos</label>
          <label><input type="checkbox" value="7" className="inline" />City Meetups</label>
          <label><input type="checkbox" value="8" className="inline" />Wiki</label>
          <label><input type="checkbox" value="9" className="inline" />Forum</label>
          <label><input type="checkbox" value="10" className="inline" />Additional Courses</label>
          <label htmlFor="comments">Any comments or suggestions?
            <textarea id="comments" name="comments" rows="5" cols="30" placeholder="Enter your comment here...">
            </textarea>
          </label>
        </fieldset>
        <input type="submit" id="submit" value="Submit" />
      </form>
    </div>
  )
}