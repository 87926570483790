import React from 'react';
import { /*useEffect,*/ useState } from 'react';
//import * as d3 from 'd3';
//import axios from 'axios';
import VgTreeApp from './VgTreeApp';
import MvTreeApp from './MvTreeApp';
import KsTreeApp from './KsTreeApp'


function TreeMapApp(props) {
  const[dispData, setDispData] = useState('vgData')
  
  
  const handleVgClick = () => {
    setDispData('vgData');
  };

  const handleMvClick = () => {
    setDispData('mvData');
  };

  const handleKsClick = () => {
    setDispData('ksData');
  };

  return (
    
    <div id="treeMapContainer" >
      <div id="treemap-app" >
        <p>
          <span className="link-text" onClick={handleVgClick} >Video Game Data Set</span><span className="hide-narrow">  |  </span><span className="link-text" onClick={handleMvClick} >Movies Data Set</span><span className="hide-narrow">  |  </span><span className="link-text" onClick={handleKsClick} >Kickstarter Data Set</span>
        </p>
      
        {dispData === 'vgData'
          ? <VgTreeApp d3Width={props.d3Width} d3Height={props.d3Height} />
          : dispData === 'mvData'
            ? <MvTreeApp d3Width={props.d3Width} d3Height={props.d3Height} />
            : <KsTreeApp d3Width={props.d3Width} d3Height={props.d3Height} />
        }
      </div>
    </div>
  )
}


export default TreeMapApp