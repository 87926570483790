import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
//import { prettyObjArr } from '../functions/prettyObjectArray.js'

function ExerciseTrackerApp() {
  //const [exData, setExData] = useState(null);
  const [respDisp, setRespDisp] = useState('');
  //const [exLogUser, setExLogUser] = useState('');
  //const [exLogApi, setExLogApi] = useState('/api/users/exercises?username=')
  const [apiStr, setApiStr] = useState('')

  const location = useLocation();
  const { username } = useParams();

  /** */
  useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      //.then((data) => setExData(data.message));

      if(respDisp === '' && apiStr !== '' ){
        axios.get(apiStr)
        .then((response) => {
          setRespDisp(response.data)
        })
        .catch((error) => {
          console.error('There was an error fetching the data!', error)
        } );
  
      }
    
  });

  if(apiStr === '' && location.pathname === '/exercise/api/users'){
    setApiStr('/exercise/api/users')
    //console.log(nodeResp)
  } else if(apiStr === '' && location.pathname === `/exercise/api/users/${username}/logs`){
    //console.log(location)
    setApiStr(`/exercise/api/users/${username}/logs` + location.search)
  }
  /*
  const userListClick = () => {
    axios.get("/api/users")
      .then((response) => {
        setRespDisp(response.data)
      })
      .catch((error) => {
        console.error('There was an error fetching the users!', error)
      } );
  }

  function handleUserChg (event) {
    setExLogUser(event.target.value)
    setExLogApi('/api/users/exercises?username=' + event.target.value)
  }

  function userExerLogClick() {
    
    axios.get(exLogApi)
      .then((response) => {
        setRespDisp(response.data)
        setExLogApi('/api/users/exercises?username=')
        setExLogUser('')
      })
      .catch((error) => {
        console.error('There was an error fetching the exercise log!', error)
      } );
  }
      //console.log('respDisp: ' + respDisp)
      //console.log('exLogApi: ' + exLogApi)
*/
  return (

    <div id="exerTrackContainer">
      <div id="trackerContainer" >
        <div className="row">
          <h1>Exercise tracker</h1>
        </div>
        <div id="formsContainer">
          <div className="formCol">
            <form action="/api/users" method="post">
              <h2>Create a New User</h2>
              {/*<p><code>POST /api/users</code></p>*/}
              <input id="uname" type="text" name="username" placeholder="username" />
              <input type="submit" value="Submit" />
            </form>
            <form action="/api/users/exerciseslog" method="post" id="exLogForm" >
              <h2>Get User Exercise Log</h2>
              {/*<p><code>POST /api/users</code></p>*/}
              <input id="uname" type="text" name="username" placeholder="username" />
              <input type="submit" value="Submit" />
            </form>
            {/*}
            <div className="userLogQuery" >
              <h2 id="userLogHead" >Get User Exercise Log</h2>
              <input placeholder="username" onChange={handleUserChg} value={exLogUser} />
              <button onClick={userExerLogClick}>Submit</button>
            </div>
            */}
          </div>
          <div className="formCol">
            <form action="/api/users/exercises" id="exercise-form" method="post">
              <h2>Add Exercises</h2>
              {/*<p><code>POST /api/users/:_id/exercises</code></p>*/}
              <input id="user" type="text" name="username" placeholder="username*" />
              <input id="desc" type="text" name="description" placeholder="description*" />
              <input id="dur" type="text" name="duration" placeholder="duration (mins.)" />
              <input id="date" type="text" name="date" placeholder="date* (yyyy-mm-dd)" />
              <p id="required-note" >* - Required</p>
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
        <div id="partial-ex-log-text" >
          <p>
            <strong>GET user's PARTIAL exercise log: </strong>
          </p>
          <p id="url-example" >
            <code>/exercise/api/users/</code><code>*username*/logs?[from=*date*</code><code>&amp;to=*date*][&amp;limit=*number*]</code>
          </p>
          <div id='get-log-legend' >
            <p><strong>[ ]</strong> = optional</p>
            <p><strong>from, to</strong> = dates (yyyy-mm-dd)</p> 
            <p><strong>limit</strong> = number</p>
            <p>Don't Include <strong>* *</strong> 's and <strong>[ ]</strong> 's</p>
          </div>
        </div> 
        {/*
        {respDisp === ''
          ? <div id="server-resp-div" className="row" ><h2>Server Response:</h2><p>Waiting for request...</p></div>
          : prettyObjArr(respDisp) === '{"Error":"Please Refresh"}'
            ? <div id="server-resp-div" ><h2>Server Response:</h2><p>Waiting for request...</p></div>
            : <div id="server-resp-div" >
                <h2>Server Response:</h2> 
                <p style={{ whiteSpace: "pre-wrap" }} >{prettyObjArr(respDisp)}</p>
              </div>
        }
        <div id="temp-stuff" >
          <h2 >Temp Stuff for Nick</h2>
          <button onClick={userListClick} >All Users List</button>
          {/*<button >Delete All Data</button>
          <p>testing: {exData}</p>
        </div>*/}
        
      </div>
    </div>
  )
}

export default ExerciseTrackerApp