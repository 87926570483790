import React from 'react';
//import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem, faTruck, faMedal } from '@fortawesome/free-solid-svg-icons';

export default function Trombones (){
  //const[showPage, setShowPage] = useState('trombones')

  return(
    <div id='trombones-app'>
      <div id="header" >
        <img id="header-img" alt="" src="https://cdn.freecodecamp.org/testable-projects-fcc/images/product-landing-page-logo.png" />
        <div id="nav-bar">
          <ul>
            <li><a className="nav-link" href="#features" >Features</a></li>
            <li><a className="nav-link" href="#how-it-works" >How It Works</a></li>
            <li><a className="nav-link" href="#pricing" >Pricing</a></li>
          </ul>
        </div>
      </div>
      <div id="get-started">
        <h2 id="email-header">Handcrafted, home-made masterpieces</h2>
        <form id="form" >
          <input id="email" type="email" name="email" required placeholder="Enter your email address" />
          <input id="submit" name="submit" type="submit" value="Get Started" />
        </form>
      </div>
      <div id="features" >
        <div id="premium-materials" className="logod-divs">
          <FontAwesomeIcon icon={faGem} />
          <section className="features-detail">
            <h2>Premium Materials</h2>
            <p>Our trombones use the shiniest brass which is sourced locally. This will increase the longevity of your purchase.</p>
          </section>
        </div>
        <div id="fast-shipping" className="logod-divs">
        <FontAwesomeIcon icon={faTruck} />
          <section className="features-detail">
            <h2>Fast Shipping</h2>
            <p>We make sure you recieve your trombone as soon as we have finished making it. We also provide free returns if you are not satisfied.</p>
          </section>
        </div>
        <div id="quality-assurance" className="logod-divs">
          <FontAwesomeIcon icon={faMedal} />
          <section className="features-detail">
            <h2>Quality Assurance</h2>
            <p>For every purchase you make, we will ensure there are no damages or faults and we will check and test the pitch of your instrument.</p>
          </section>
        </div>
      </div>

      <div id="how-it-works">
        <iframe id="video" title={"ytexample"} height="315" src="https://www.youtube.com/embed/y8Yv4pnO7qc" frameBorder="0" allowFullScreen=""></iframe>
      </div>

      <div id="pricing-container">
        <div id="pricing">
          <section className="price-tier">
            <h3>TENOR TROMBONE</h3>
            <h2>$600</h2>
            <p>Brass from Bronze Age</p>
            <p>Lorem ipsum.</p>
            <p>Lorem ipsum dolor.</p>
            <p>Lorem ipsum.</p>
            <input className="tier-select" name="tier-select" type="submit" value="SELECT" />
          </section>
          <section className="price-tier">
            <h3>BASS TROMBONE</h3>
            <h2>$900</h2>
            <p>Great at parties</p>
            <p>Lorem ipsum.</p>
            <p>Lorem ipsum dolor.</p>
            <p>Lorem ipsum.</p>
            <input className="tier-select" name="tier-select" type="submit" value="SELECT" />
          </section>
          <section className="price-tier">
            <h3>VALVE TROMBONE</h3>
            <h2>$1200</h2>
            <p>Plays similar to a Trumpet</p>
            <p>Great for Jazz Bands</p>
            <p>Lorem ipsum dolor.</p>
            <p>Lorem ipsum.</p>
            <input className="tier-select" name="tier-select" type="submit" value="SELECT" />
          </section>
        </div>
      </div>

      <footer>
        <ul>
          <li className="foot-links"><span>Privacy</span></li>
          <li className="foot-links"><span>Terms</span></li>
          <li className="foot-links"><span>Contact</span></li>
        </ul>
        <p id="copyright">Copyright 2016, Original Trombones</p>
      </footer>

    </div>
  )
}