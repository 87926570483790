import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';

export default class QuoteMachine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrIndex: 0,
      usingSat: 100,
      usingLight: 30,
      usingHue: 24
    };
   this.newQuote = this.newQuote.bind(this);
  }

    
    newQuote() {
      this.setState(() => ({
        usingSat: 0,
        usingLight: 100
      }));
      setTimeout(() => {
        this.setState(state => ({
          arrIndex: state.arrIndex + 1,
          usingSat: 100,
          usingLight: 30,
          usingHue: Math.random() * 359
        }));
      }, 1000);
    };
    
  render() {
    const quoteList = [
      ["Veni, Vidi, Vici", "Julius Caesar"],
      ["The way to get started is to quit talking and begin doing", "Walt Disney"],
      ["You must be the change you wish to see in the world", "Mahatma Gandhi"],
      ["The only thing we have to fear is fear itself", "Franklin D. Roosevelt"],
      ["Well done is better than well said", "Benjamin Franklin"],
      ["If you hear a voice within you say 'You cannot paint,' then by all means paint and that voice will be silenced.", "Vincent Van Gogh"],
      ["In the darkest of times, hope is something that you give yourself. That is the meaning of inner strength.", "Uncle Iroh"]
    ]
    
    let toWhiteText = 'hsl(' + this.state.usingHue + ', ' + this.state.usingSat + '%,' + this.state.usingLight + '%)';
    let newBackColor = 'hsl(' + this.state.usingHue + ', 100%, 30%)';
    
    let varBack = {
      backgroundColor: newBackColor,
      //backgroundColor: 'hsl(24, 70%, 50%)',
      transition: "all 1s"
    }
    
    let varText = {
      color: toWhiteText,
      //color: 'hsl(24, 70%, 50%)',
      transition: "all 1s"
    }

    let useIndex = 0;
       
    if(this.state.arrIndex < quoteList.length){
      useIndex = (this.state.arrIndex)
    } else {
      useIndex = (this.state.arrIndex) % quoteList.length
    }
    
    /* was in quote-app-container: style={varBack} */
    return (
      <div id="quote-app-container" className="container-fluid background varyBack" >
        <div id="quote-box-wrapper" style={varBack} >
          <div id="quote-box">
            <h1 id="text" style={varText}>"{quoteList[useIndex][0]}"</h1>
            <h3 id="author" style={varText}>-{quoteList[useIndex][1]}</h3>

            <div id="allButtons" >
              <div id="socialButtons" >
                <a className="twitterTumblr" style={varBack} id="tweet-quote"
                  href="https://x.com/compose/tweet" target="_blank" rel="noreferrer">Tweet!</a>
                <a className="twitterTumblr" style={varBack} 
                  href="https://www.tumblr.com/" target="_blank" rel="noreferrer">Tumbl!</a> 
              </div>

              <div id="newQuoteArea" >
                <button id="new-quote" onClick={this.newQuote} style={varBack}>
                New Quote</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}