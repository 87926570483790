import React from 'react';
import { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import { axiosFetch } from '../axiosFun.js'


export default function ScatterPlot(props, {width = props.d3Width, height = props.d3Height}) {
  //const[rectData, setRectData] = useState([])
  const[loading, setLoading] = useState('still loading')
  const apiUrl = 'https://raw.githubusercontent.com/freeCodeCamp/ProjectReferenceData/master/cyclist-data.json';

  const ref = useRef()
  
  useEffect(() => {
    const drawScatter = async () => {
      /*
      function timeString(num){
        let minutes = Math.floor(num / 60);
        let seconds = 0;
      
        if(num % 60 < 10){
          seconds = "0" + num % 60;
        } else {
          seconds = num % 60
        }
      
        return minutes + ':' + seconds;
      }*/
      
      function dopingStr(str){
        if (str === ""){
          return ""
        } else {
          return "<br><br>" + str
        }
      }

      //assign data pulled in to a variable, array of objects
      //let rawCyclingData = response.data;
      let rawCyclingData = await axiosFetch(apiUrl);
      //console.log(rawCyclingData)
      //console.log(rawCyclingData[0])

      //variables assigned to help set data/axis areas
      //const width = 750;
      //const height = 370;
      const sidePadding = 80;
      const vertPadding = 40;

      const xScale = d3.scaleLinear()
        .domain(
          [d3.min(rawCyclingData, (d) => d["Year"] - 1),
          d3.max(rawCyclingData, (d) => d["Year"] + 1)]
        )
        .range([sidePadding, width - sidePadding + 40])
      
      const yScale = d3.scaleLinear()
        .domain(
          [d3.max(rawCyclingData, (d) => d["Seconds"]),
          d3.min(rawCyclingData, (d) => d["Seconds"])]
        )
        .range([height - vertPadding, vertPadding - 10])

      //initial svg created, axes, labels, legend, and plot points appended to this
      const svg = d3.select("#scatter-svg")
        //.append("svg")
        //.attr("width", width)
        //.attr("height", height);
      
      //tooltip
      const toolTip = d3.select("#tooltip")
        //.append("div")
        //.attr("class", "tooltip")

      //mouseover, move, leave logic
      const mouseOver = function(event, d) {
        toolTip
          //.html(d["Name"] + ": " + d["Nationality"] + "<br>Year: " + d["Year"] + ", Time: " + d["Time"] + dopingStr(d["Doping"]))
          .style("left", event.clientX + 30 + "px")
          .style("top", event.clientY - vertPadding + "px" )
          .style('visibility', 'visible')
          .style("opacity", "0.95")
      }

      const mouseMove = function(event, d) {
        toolTip
          .html(d["Name"] + ": " + d["Nationality"] + "<br>Year: " + d["Year"] + ", Time: " + d["Time"] + dopingStr(d["Doping"]))
      }

      const mouseLeave = function() {
        toolTip 
          .style("opacity", "0")
          .style('visibility', 'hidden')
      }

      //add scatter plot points
      svg.selectAll("circle")
        .data(rawCyclingData)
        .enter()
        .append("circle")
        .attr("cx", (d) => xScale(d["Year"]))
        .attr("cy", (d) => yScale(d["Seconds"]))
        .attr("r", (d) => 6)
        .style("fill", (d) => {
          if (d["Doping"] === ""){
            return "hsl(24, 70%, 50%)"
          } else {
            return "hsl(210, 100%, 60%)"
          }
        })
        .style("opacity", "0.9")
        .style("stroke", "white")
        .style("z-index", "10")
        .on("mouseover", mouseOver)
        .on("mousemove", mouseMove)
        .on("mouseleave", mouseLeave)   

      //legend
      //no dope text
      svg.append('text')
        .text("No doping allegations -")
        .attr("x", width - 179)
        .attr('y', height - 250)
        .style("font-size", "0.8em")
        .style("fill", "white")
        .style("font-weight", "200")

      //no dope color rect
      svg.append("rect")
        .attr("x", width - 47)
        .attr("y", height - 261)
        .style("width", "15px")
        .style("height", "15px")
        .style("fill", "hsl(24, 70%, 50%)")
        .style("outline", "1px solid white")

      svg.append('text')
        .text("Riders with doping allegations -")
        .attr("x", width - 225)
        .attr('y', height - 229)
        .style("font-size", "0.8em")
        .style("fill", "white")
        .style("font-weight", "200")

      //no dope color rect
      svg.append("rect")
        .attr("x", width - 47)
        .attr("y", height - 241)
        .style("width", "15px")
        .style("height", "15px")
        .style("fill", "hsl(210, 100%, 60%)")
        .style("outline", "1px solid white")
      
      //set x and y axis
      const xAxis = d3.axisBottom(xScale)
        .tickFormat(d3.format('d'));

      const yAxis = d3.axisLeft(yScale)
        .tickFormat(function(d) {
          let minutes = Math.floor(d / 60);
          let seconds = 0;
          
          if(d%60 < 10){
            seconds = "0" + d % 60;
          } else {
            seconds = d % 60
          }
          
          return minutes + ':' + seconds;
        });
    
      svg.append('g')
        .attr('transform', 'translate(0,' + (height - vertPadding) + ')')
        .call(xAxis)
        .style("font-weight", "100")
        //.style("z-index", "1");
      svg.append('g')
        .attr('transform', 'translate(' + sidePadding + ', 0)')
        .call(yAxis)
        .style("font-weight", "100")

      //axis label
      svg.append("text")
        .attr("class", "ylabel")
        .attr("y", sidePadding - 45 - 5)
        .attr("x", vertPadding - height)
        .attr("transform", "rotate(-90)")
        .text("Time In Minutes")
        .style("fill", "white")
        .style("font-weight", "100")
        .style("letter-spacing", "1px")
    }
    setLoading('Loaded API data!')
    drawScatter();
    
  }, );//[width, height]);

  return(
    <div id="scatter-container" >
      <div id="scatter-plot">
        <h1 id="plot-title">Doping in Professional Bicycle Racing</h1>
        <h2 id="sub-title">35 Fastest Times: 1994 - 2015</h2>
        <div id="scatter-svg-cont">
          <div id="tooltip" ></div>
          {loading === 'still loading'
            ? <p>SVG Loading...</p>
            : <svg width={width} height={height} id='scatter-svg' ref={ref} ></svg>
          }
        </div>  
      </div>  
    </div>
  )
}