export const BANKCHG = 'BANKCHG';

export const chgBank = (newBank) => {
  return {
    type: BANKCHG,
    bank: newBank
    };
}

export const NEWLETTER = 'NEWLETTER';

export const newLetter = (bankLetter) => {
  
    return {
    type: NEWLETTER,
    letter: bankLetter
    };
}

export const POWEROFF = 'POWEROFF';

export const powerOff = (powerStatus) => {
  return {
    type: POWEROFF,
    powerstatus: ""
  }
}

export const VOLCHANGE = 'VOLCHANGE';

export const chgVol = (volumeValue) => {
  //console.log(typeof volumeValue)
  return {
    type: VOLCHANGE,
    volume: volumeValue
  }
}