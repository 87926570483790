import React from 'react';
import { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import { axiosFetch } from '../axiosFun.js'


export default function BarChart(props, {width = props.d3Width, height = props.d3Height}) {
  //const[rectData, setRectData] = useState([])
  const[loading, setLoading] = useState('still loading')
  const apiUrl = 'https://raw.githubusercontent.com/freeCodeCamp/ProjectReferenceData/master/GDP-data.json';

  const ref = useRef()
  
  useEffect(() => { 
    const drawBarChart = async () => {
      /**/
      function dateToNum(date) {
        let year = Number(date.slice(0, 4));
        let month = Number(date.slice(5, 7));
      
        return year + (month - 1)/12;
      }
      
      function gdpStrToNums(arr){
        let gdpNums = [];
        let newDataPoint = [];
      
        for(let i = 0; i < arr.length; i++){
          newDataPoint = [dateToNum(arr[i][0]), arr[i][1]];
          gdpNums.push(newDataPoint);
          newDataPoint = [];
        }
      
        return gdpNums;
      }
      
      function quarterStr(dateNum){
        let roundDnNum = Math.floor(dateNum);  
        let yearStr = roundDnNum.toString();
        
        if(dateNum - roundDnNum === 0){
          return yearStr + " Q1"
        } else if(dateNum - roundDnNum === 0.25){
          return yearStr + " Q2"
        } else if(dateNum - roundDnNum === 0.5){
          return yearStr + " Q3"
        } else if(dateNum - roundDnNum === 0.75){
          return yearStr + " Q4"
        } else {
          return "quarterStr issue!"
        }
      }
      
      function gdpStr(gdpNum){
        let iniGdpStr = gdpNum.toString();
        if(gdpNum < 1000){
          let gdpNumStr = iniGdpStr;
          return "$" + gdpNumStr + " Billion"
        } else if(iniGdpStr[iniGdpStr.length - 2] !== ".") {
          let gdpNumStr = iniGdpStr.slice(0, iniGdpStr.length - 3) + "," + iniGdpStr.slice(iniGdpStr.length - 3, iniGdpStr.length)
          return "$" + gdpNumStr + ".0 Billion"
        } else {
          let gdpNumStr = iniGdpStr.slice(0, iniGdpStr.length - 5) + "," + iniGdpStr.slice(iniGdpStr.length - 5, iniGdpStr.length)
          return "$" + gdpNumStr + " Billion"
        }
      }

      //let gdpData = gdpStrToNums(response.data.data);
      let gdpApiResp = await axiosFetch(apiUrl);
      let gdpData = gdpStrToNums(gdpApiResp.data);
      //console.log(gdpData);
      //console.log(gdpData[0])
      //console.log(gdpData[gdpData.length - 1])
      //const w = 750;
      //const h = 350;
      const padding = 40;
      const xScale = d3.scaleLinear()
        .domain([d3.min(gdpData, (d) => d[0]), d3.max(gdpData, (d) => d[0]) + 0.25])
        .range([padding, width - padding/10]);
      const yScale = d3.scaleLinear()
        .domain([0, d3.max(gdpData, (d) => d[1])])
        .range([height - padding, padding])

      //toolTip creation
      const toolTip = d3.select("#tooltip")
        //.append('div')
        //.style('opacity', "0")
        //.style('background-color', 'hsl(24, 100%, 4%)')//hsl(210, 30%, 60%)')
        //.style('color', 'white')
        //.style("box-shadow", "0px 0px 15px")
        //.style("text-align", "center")
        //.style("width", "125px")
        //.style("position", "fixed")
        //.style("left", (event.clientX + 30 + "px"))
        //.style("top", 300 + "px" )
        
      //functions describing mouse over, mouse move and mouse leave logic
      const mouseOver = function(d) {
        toolTip
          //.attr("class", "tooltip")
          .style("animation", "fadeIn 0.2s linear 1")
          .style("opacity", "0.8")
        d3.select(this)
          .style("opacity", "0.2")
      }
      
      const mouseMove = function(event, d) {
        toolTip
          .html(quarterStr(d[0]) + "<br>" + gdpStr(d[1]))
          .style("left", event.clientX - 60 + "px")
          .style("top", (event.clientY - 100 + document.documentElement.scrollTop) + "px" )
      }
      
      const mouseLeave = function() {
        toolTip        
          .style("animation", "fadeOut 0.2s linear 1")
          .style("opacity", "0")
        d3.select(this)
          .style("opacity", "1")
      }
      
      const svg = d3.select("#bar-chart-svg")
      /*
        .append("svg")
        .attr("width", w)
        .attr("height", h);
      */

      svg.selectAll("rect")
        .data(gdpData)
        .enter()
        .append("rect")
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]))
        .attr("width", 3)
        .attr("height", (d) => height - padding - yScale(d[1]))
        //.attr("fill", "hsl(204, 100%, 60%)")
        .attr("class", "bar")
          .on("mouseover", mouseOver)
          .on("mousemove", mouseMove)
          .on("mouseleave", mouseLeave)

      const xAxis = d3.axisBottom(xScale)
                    .tickFormat(d3.format("d"));

      const yAxis = d3.axisLeft(yScale);

      svg.append("g")
        .attr("transform", "translate(0," + (height - padding) + ")")
        .call(xAxis);
      svg.append("g")
        .attr("transform", "translate(" + padding + ",0)")
        .call(yAxis)

      /*
      svg.append("text")
      .attr("class", "xlabel")
      .attr("x", w/2)
      .attr("y", h - 6)
      .text("Year");
      */
      svg.append("text")
      .attr("class", "ylabel")
      .attr("y", 60)
      .attr("x", -130)
      .attr("transform", "rotate(-90)")
      .text("GDP (Billions)");

    }

    setLoading('Loaded API data!')
    drawBarChart();
  }, );//[width, height]);

  return(
    <div id="bar-container">
      
      <div id="bar-chart-app">
        <h1 id="title">United States GDP</h1>
        <p>1947 - 2015</p>
        <div id="bar-chart">
          <div id="tooltip" ></div>
          {loading === 'still loading'
            ? <p>SVG Loading...</p>
            : <svg width={width} height={height} id='bar-chart-svg' ref={ref} ></svg>
          }
        </div>  
      </div>
    </div>
  )
}