export function projectTileData(showAllStatus) {
  const homeTileData = [
      [
        "React-Node.js Portfolio",
        "This website! Node backend and React front end, with state managed via Redux, and styled with SASS/Bootstrap. Apps built with React, Redux, D3.js, Node, Mongoose, and PostgreSQL",
        "React-Node.js Portfolio",
        "../images/portfolioImg.png",
        ""
      ],
      [
        "React-Redux 25 + 5 Timer",
        "25 + 5 Timer productivity timer for planned work and break timing. React component, with state managed via Redux, and styled with SASS/Bootstrap.",
        "React-Redux 25 + 5 Timer",
        "../images/twentyFiveImg.png",
        "twentyFive",

      ],
      [
        "React-Redux Calculator",
        "Calculator app for basic operations. React component, with state managed via Redux, and styled with SASS/Bootstrap.",
        "React-Redux Calculator",
        "../images/calculatorImg.png",
        "calculator"
      ],
      [
        "Mongoose Exercise Tracker",
        "Exercise Tracker using Node.js backend and a Mongoose database. React component, with state managed via Redux, and styled with SASS.",
        "Mongoose Exercise Tracker",
        "../images/exTrackImg.png",
        "exercise"
      ],
      [
        "PSQL Number Guessing Game",
        "Number guessing game using Node.js backend and a PostgreSQL database. React component, with state managed via Redux, and styled with SASS.",
        "PSQL Number Guessing Game",
        "../images/numGuessImg.png",
        "numguess"
      ],
      [
        "D3.js Treemap",
        "Treemap of a few different financial datasets. Created with D3, inside a React component, styled with SASS",
        "D3.js Treemap",
        "../images/treemapImg.png",
        "treemap"
      ],
      
  ]

  const remainTileData = [
    [
      "React Markdown Previewer",
      "Markdown editor and previewer. React component styled with SASS/Bootstrap.",
      "React Markdown Previewer",
      "../images/markdownImg.png",
      "markdown"
    ],
    [
      "React Quote Generator",
      "My first React app, a quote generator. React component styled with SASS.",
      "React Quote Generator",
      "../images/quoteImg.png",
      "quotegenerator"
    ],
    [
      "React/Redux Drum App",
      "Drum/instrument React/Redux App. React component with state managed via Redux, and styled with SASS.",
      "React/Redux Drum App",
      "../images/drumImg.png",
      "drum"
    ],
    [
      "D3.js Bar Graph",
      "D3.js bar graph of US GDP data by quarter and year from 1947 - 2015. React component styled with SASS.",
      "D3.js Bar Graph",
      "../images/barImg.png",
      "bargraph"
    ],
    [
      "D3.js Scatterplot",
      "D3.js scatterplot of professional cycling doping data. React component styled with SASS.",
      "D3.js Scatterplot",
      "../images/scatterImg.png",
      "scatterplot"
    ],
    [
      "D3.js Heat Map",
      "D3.js heat map of monthly global land-surface temperature data. React component styled with SASS.",
      "D3.js Heat Map",
      "../images/heatImg.png",
      "heatmap"
    ],
    [
      "D3.js Choropleth Map",
      "D3.js choropleth map of US educational data by county. React component styled with SASS.",
      "D3.js Choropleth Map",
      "../images/choroImg.png",
      "choropleth"
    ],
    [
      "HTML/CSS Pages",
      "A variety of my early projects while I was learning HTML/CSS, turned into a React component.",
      "HTML/CSS Pages",
      "../images/earlyImg.png",
      "earlywork"
    ],
  ]

  let comboData = [...homeTileData, ...remainTileData]

  if(showAllStatus === false) {
    return homeTileData
  } else {
    return comboData
  }
  
}