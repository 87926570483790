//import { combineReducers } from 'redux';
import { BANKCHG, NEWLETTER, POWEROFF, VOLCHANGE } from './drum-actions.js';

export const bankLoc = (state = "", action) => {
  switch (action.type) {
    case BANKCHG:
      if(action.bank === "Smooth Piano Kit"){
        return "right";
      } else if(action.bank === "Heater Kit") {
        return "left";
      } else {
        return "right";
      }
    default:
      return state;
  }
}

export const playLinks = (state = "", action) => {
  const heaterLinks = {
    Q: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-1.mp3',
    W: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-2.mp3',
    E: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-3.mp3',
    A: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3',
    S: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-6.mp3',
    D: 'https://s3.amazonaws.com/freecodecamp/drums/Dsc_Oh.mp3',
    Z: 'https://s3.amazonaws.com/freecodecamp/drums/Kick_n_Hat.mp3',
    X: 'https://s3.amazonaws.com/freecodecamp/drums/RP4_KICK_1.mp3',
    C: 'https://s3.amazonaws.com/freecodecamp/drums/Cev_H2.mp3'
  }
  const smoothPianoLinks = {
    Q: 'https://s3.amazonaws.com/freecodecamp/drums/Chord_1.mp3',
    W: 'https://s3.amazonaws.com/freecodecamp/drums/Chord_2.mp3',
    E: 'https://s3.amazonaws.com/freecodecamp/drums/Chord_3.mp3',
    A: 'https://s3.amazonaws.com/freecodecamp/drums/Give_us_a_light.mp3',
    S: 'https://s3.amazonaws.com/freecodecamp/drums/Dry_Ohh.mp3',
    D: 'https://s3.amazonaws.com/freecodecamp/drums/Bld_H1.mp3',
    Z: 'https://s3.amazonaws.com/freecodecamp/drums/punchy_kick_1.mp3',
    X: 'https://s3.amazonaws.com/freecodecamp/drums/side_stick_1.mp3',
    C: 'https://s3.amazonaws.com/freecodecamp/drums/Brk_Snr.mp3'
  }

  switch (action.type){
    case BANKCHG:
      if(action.bank === "Smooth Piano Kit"){
        return smoothPianoLinks;        
      } else if(action.bank === "Heater Kit") {
        return heaterLinks;
      }
      break;
    default:
      return state;
  }
}

export const volumeLevel = (state = [], action) => {
  //console.log(typeof action.volume);
  switch (action.type) {
    case VOLCHANGE:
      return action.volume;
    default:
      return state;
  }
}

export const displayMsg = (state = "", action) => { //reducer
  const soundNames = {
    heaterQ: "Heater 1",
    heaterW: "Heater 2",
    heaterE: "Heater 3",
    heaterA: "Heater 4",
    heaterS: "Clap",
    heaterD: "Open HH",
    heaterZ: "Kick n' Hat",
    heaterX: "Kick",
    heaterC: "Closed HH",
    pianoQ: "Chord 1",
    pianoW: "Chord 2",
    pianoE: "Chord 3",
    pianoA: "Shaker",
    pianoS: "Open HH",
    pianoD: "Closed HH",
    pianoZ: "Punchy Kick",
    pianoX: "Side Stick",
    pianoC: "Snare"
  }

  switch (action.type) {
    
    case BANKCHG:
      return action.bank;
    case NEWLETTER:
      return soundNames[action.letter];
    case POWEROFF:
      return "";
    case VOLCHANGE:
      return "Volume: " + action.volume;
    default:
      return state;
  }
};

//
/*
const rootReducer = combineReducers({
  displayMsg,
  bankLoc,
  playLinks,
  volumeLevel
});

export default rootReducer;
*/