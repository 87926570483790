import React from 'react';
import { useState } from 'react';
import axios from 'axios';
//import { useParams, useLocation } from 'react-router-dom';
import { prettyObjArr } from '../functions/prettyObjectArray.js'

function NumberGuessApp() {
  //const [exData, setExData] = useState(null);
  const [respDisp, setRespDisp] = useState('Enter your username below...');
  const [userIn, setUserIn] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [guesserApi, setGuesserApi] = useState('/api/numberguesser?username=&guess=');
  const [guessIn, setGuessIn] = useState('');

  //const location = useLocation();
  //const { username } = useParams();

  function handleUserChg (event) {
    setUserIn(event.target.value)
    setGuesserApi('/api/numberguesser?username=' + event.target.value + '&guess=')
  }

  function usernameSubmit() {
    if(userIn !== ''){
      axios.get(guesserApi)
        .then((response) => {
          setRespDisp(response.data)
          setCurrentUser(userIn)
          setUserIn('')
          setGuesserApi('/api/numberguesser?username=' + currentUser + '&guess=')
        })
        .catch((error) => {
          console.error('There was an error fetching the exercise log!', error)
        });
    }
  }

  function handleGuessChg (event) {
    setGuessIn(event.target.value)
    setGuesserApi('/api/numberguesser?username=' + currentUser + '&guess=' + event.target.value)
  }

  function guessSubmit() {
    
    axios.get(guesserApi)
      .then((response) => {
        setRespDisp(response.data)
        setGuesserApi('/api/numberguesser?username=' + currentUser + '&guess=')
        setGuessIn('')
        
      })
      .catch((error) => {
        console.error('There was an error fetching the exercise log!', error)
      } );
  }

  return (
    <div id="num-guess-container">
      <div id="num-guess-forms">
        <div id="server-resp-div" >
          <h2>Server:</h2> 
          <p style={{ whiteSpace: "pre-wrap" }} >{prettyObjArr(respDisp)}</p>
        </div>
        {currentUser === undefined || currentUser === ''
          ? <div id="username-entry" >
              <h2 id="user-entry-head" >Enter your username:</h2>
              <input placeholder="username" onChange={handleUserChg} value={userIn} />
              <button onClick={usernameSubmit}>Submit</button>
            </div>
          : prettyObjArr(respDisp).slice(0,5) === '"Your' || prettyObjArr(respDisp).slice(0,5) === '"Nice' 
            ? <div id="emptyDiv"></div>
            : <div id="num-guess-entry">
                <h2 id="guess-entry-head" >Enter your guess:</h2>
                <input placeholder="guess" onChange={handleGuessChg} value={guessIn} />
                <button onClick={guessSubmit}>Submit</button>
              </div>
        }
        
      </div>
    </div>
  )
}

export default NumberGuessApp