import React from 'react';
import { useState } from 'react';
import Trombones from './Trombones';
import JsDocPage from './JsDocPg.jsx';
import TributePage from './TributePage.jsx';
import SurveyForm from './SurveyForm.jsx';

export default function EarlyWork (){
  const[showPage, setShowPage] = useState('jsdoc')

  const handleTrombonesClick = () => {
    setShowPage('trombones');
  }

  const handleJsDocClick = () => {
    setShowPage('jsdoc');
  }

  const handleTributeClick = () => {
    setShowPage('tribute');
  }

  const handleSurveyClick = () => {
    setShowPage('survey');
  }

  return(
    <div id='early-work-container'>
      <div id="early-work-app">
        <p>
          <span className="link-text" onClick={handleJsDocClick} >JS Documentation</span><span className="hide-narrow" >&nbsp;&nbsp; | &nbsp;&nbsp;</span>
          <span className="link-text" onClick={handleTrombonesClick} >Trombones Product Page</span><span className="hide-narrow" >&nbsp;&nbsp; | &nbsp;&nbsp;</span>
          <span className="link-text" onClick={handleTributeClick} >Tribute Page</span><span className="hide-narrow" >&nbsp;&nbsp; | &nbsp;&nbsp;</span>
          <span className="link-text" onClick={handleSurveyClick} >Survey Form</span>
        </p>
        {showPage === 'jsdoc'
          ? <JsDocPage />
          : showPage === 'trombones'
            ? <Trombones />
            : showPage === 'tribute'
              ? <TributePage />
              : <SurveyForm />
        }
      </div>
    </div>
  )
}