import React from 'react';
import { useLayoutEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import { axiosFetch } from '../axiosFun.js';
import * as topojson from 'topojson-client'


export default function ChoroMap(props, {width = props.d3Width, height = props.d3Height}) {
  //const[rectData, setRectData] = useState([])
  const[loading, setLoading] = useState('still loading');
  const[edData, setEdData] = useState([])
  const[coData, setCoData] = useState([])
  const topoApiUrl = 'https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/counties.json';
  const eduApiUrl = 'https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/for_user_education.json';

  
  const ref = useRef()

  useLayoutEffect(() => {
    async function apiDataPull(){
      if(edData.length === 0){
        const educData = await axiosFetch(eduApiUrl)
        setEdData(educData)
        
      } else if(coData.length === 0){
        const ctyData = await axiosFetch(topoApiUrl)
        setCoData(ctyData)
      }
      //console.log(edData)
    }
  
    const drawChoroMap = async () => {
      function eduPercToColor(eduPerc) {
        if(eduPerc < 12) {
          return "#e5f5e0"
        } else if(eduPerc >= 12 && eduPerc < 21){
          return "#c7e9c0"
        } else if(eduPerc >= 21 && eduPerc < 30){
          return "#a1d99b"
        } else if(eduPerc >= 30 && eduPerc < 39){
          return "#74c476"
        } else if(eduPerc >= 39 && eduPerc < 48){
          return "#41ab5d"
        } else if(eduPerc >= 48 && eduPerc < 57){
          return "#238b45"
        } else if(eduPerc >= 57){
          return "#006d2c"
        }
      }

      const topoResponse = await axiosFetch(topoApiUrl);
      //console.log('topoResponse: ')
      //console.log(topoResponse)
      const topoData = topoResponse//topo -> topology
      const countyData = topoResponse.objects.counties
      //console.log(countyData)
      const stateData = topoResponse.objects.states

      const eduData = await axiosFetch(eduApiUrl);//eduResponse.data
      //console.log('eduData: ')
      //console.log(eduData)
      
      let fipsObj = {}
      for(let i = 0; i < eduData.length; i++){
        fipsObj['' + eduData[i]["fips"]] = [eduData[i]['area_name'], eduData[i]['state'], eduData[i]['bachelorsOrHigher']];
      }

      //console.log('fipsKeysObj:')
      //console.log(fipsObj)
      //variables for svg sizing
      //const width = 1000;
      //const height = 600;
      const vertPadding = 20;
      //const sidePadding = 70;
    
      //initial svg created
      const svg = d3.select('#choropleth-svg')
      /*
        .append("svg")
        .attr('width', width )
        .attr("height", height)
        */
    
      //x and yscale ended up being unneccessary - topojson.feature into d3.geopath sets its own scale somehow
      /* 
      //scaling set for the choro map
      const xScale = d3.scaleLinear()
        //.domain([]) // not sure until we decipher arcs
        .range([sidePadding, width - sidePadding])
    
      const yScale = d3.scaleLinear()
        //.domain([]) // not sure until we decipher arcs
        .range([vertPadding, height - vertPadding])
    */
      //delete below once we don't need to know what topojson -> geojson obj looks like
      //console.log('topojson -> geojson obj: ')
      //console.log(topojson.feature(topoData, countyData).features);

      //tooltip 
      const toolTip = d3.select("#tooltip")//"#choropleth-map")
        //.append("div")
        //.attr('id', 'tooltip')
        //.html('hello' + 2 + 'dude')
      
      //mouseover/mousmove/mouseleave functions
      const mouseOver = function(event) {
        toolTip
          .style('visibility', 'visible')
          //.style('top', (event.clientY - 25 + window.scrollY) + 'px')
          //.style('left', (event.clientX + 10) + 'px')

        d3.select(this)
          .style("opacity", "0.5")
      }
      const mouseMove = function(event, d) {
        toolTip
          .html(fipsObj[d['id']][0] + ', ' + fipsObj[d['id']][1] + ': ' + fipsObj[d['id']][2] + '%')
          .style('top', (event.clientY - 100 + document.documentElement.scrollTop) + 'px')
          .style('left', (event.clientX - 45) + 'px')
      }
      const mouseLeave = function() {
        toolTip 
          .style("visibility", "hidden")

        d3.select(this)
          .style('opacity', '1')
      }
      
      svg.append('g')//const counties = svg.append('g')
        .attr('id', 'counties')
        .selectAll('path')
        .data(topojson.feature(topoData, countyData).features)
        .enter()
        .append("path")
        .attr('class', 'county')
        .attr('d', d3.geoPath())
        .attr('data-fips', (d) => d['id'])
        .style('fill', (d) => {
          //eduPercToColor takes an education value and returns the appropriate color
          //fipsObj has every fips as a key, 2 is the education value in that key's array (0=county, 1=state)
          return eduPercToColor(fipsObj[d['id']][2])
        })
        .on("mouseover", mouseOver)
        .on("mousemove", mouseMove)
        .on("mouseleave", mouseLeave) 
      
        svg.append('g')//const states = svg.append('g')
        .attr('id', 'states')
        .selectAll('path')
        .data(topojson.feature(topoData, stateData).features)
        .enter()
        .append('path')
        .attr('class', 'state')
        .attr('d', d3.geoPath())
      
      //legend added
      //axis first
      const legendScale = d3.scaleLinear()
        .domain([.03, .66])
        .range([width-410, width - 160])

      const legendAxis = d3.axisBottom(legendScale)
        .tickValues(d3.range(.03, .67, .09))
        .tickFormat(d3.format(".0%"))
        

      svg.append('g')
        .attr('transform', 'translate(0,' + (vertPadding + 10) + ')')
        .attr('id', 'legendAxis')
        .style("color", "white")
        .call(legendAxis)
      

      //next add the rectangles & tick marks
      for(let i = 0; i < 7; i++){
        svg.append('rect')
          .attr('class', 'legendRects')
          .attr('y', vertPadding + 1 )
          .attr('x', width - 410 + i*35.7)
          .style('fill', eduPercToColor(7+9*i))

        svg.append('line')
          .attr('class', 'longTicks')
          .attr('x1', width - 410 + i*36)
          .attr('x2', width - 410 + i*36)
          .attr('y1', vertPadding - 3)
          .attr('y2', vertPadding + 16)
          .style('stroke', 'white')
          .style('stroke-width', '1px')
      }

      svg.append('line')
      .attr('class', 'longTicks')
      .attr('x1', width - 412 + 7*36)
      .attr('x2', width - 412 + 7*36)
      .attr('y1', vertPadding - 3)
      .attr('y2', vertPadding + 16)
      .style('stroke', 'white')
      .style('stroke-width', '1px')
    }


    if (edData.length === 0){
      apiDataPull()
    } else if(coData.length === 0) {
      apiDataPull()
      //drawChoroMap()
    } else {
      drawChoroMap()
      setLoading('Loaded API data!')
    }

    //setLoading('Loaded API data!')
    //console.log('edData: ')
    //console.log(edData)
    //drawChoroMap();
  },[width, height, edData, coData]); 
  //no issues on ios, different than other d3 apps because of edData and coData

  return(
    <div id="choropleth-app">
      <div id="choropleth-map">
        <h1 id="title" >United States Educational Attainment</h1>
        <p className="subTitle">Percentage of adults age 25 and older with a bachelor's degree or higher</p>
        <p className="subTitle" > (2010 - 2014)</p>
        {/*edData.length === 0
          ? <p>No edData downloaded</p>
          : <p>edData downloaded</p>
        */}
        <div id="choro-map-cont">
          <div id="tooltip"></div>
          {loading === 'still loading'
            ? <p>SVG Loading...</p>
            : <svg width={width + 200} height={height + 205} id='choropleth-svg' ref={ref} ></svg>
          }
        </div>  
      </div>
    </div>
  )
}