import React from 'react';
import { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import { axiosFetch } from '../axiosFun.js'


export default function HeatMap(props, {width = props.d3Width, height = props.d3Height}) {
  //const[rectData, setRectData] = useState([])
  const[loading, setLoading] = useState('still loading')
  const apiUrl = 'https://raw.githubusercontent.com/freeCodeCamp/ProjectReferenceData/master/global-temperature.json';

  const ref = useRef()
  
  useEffect(() => { 
    const drawHeatMap = async () => {
      function monthNumToName(num){
        switch (num) {
          case 1:
            return "January"
          case 2:
            return "February"
          case 3:
            return "March"
          case 4:
            return "April"
          case 5:
            return "May"
          case 6:
            return "June"
          case 7:
            return "July"
          case 8:
            return "August"
          case 9:
            return "September"
          case 10:
            return "October"
          case 11:
            return "November"
          case 12:
            return "December"
          default:
            break;
        }
      }
      
      function variToColor(variance){
        let monthTemp = variance + 8.66
        
        if(monthTemp < 3.9){
          return "rgb(69, 117, 180)"
        } else if(monthTemp >= 3.9 && monthTemp < 5.0){
          return "rgb(116, 173, 209)"
        } else if(monthTemp >= 5.0 && monthTemp < 6.1){
          return "rgb(171, 217, 233)"
        } else if(monthTemp >= 6.1 && monthTemp < 7.2){
          return "rgb(224, 243, 248)"
        } else if(monthTemp >= 7.2 && monthTemp < 8.3){
          return "rgb(255, 255, 191)"
        } else if(monthTemp >= 8.3 && monthTemp < 9.4){
          return "rgb(254, 224, 144)"
        } else if(monthTemp >= 9.4 && monthTemp < 10.5){
          return "rgb(253, 174, 97)"
        } else if(monthTemp >= 10.5 && monthTemp < 11.6){
          return "rgb(244, 109, 67)"
        } else if(monthTemp >= 11.6 ){
          return "rgb(215, 48, 39)"
        }
      }

      let temperResp = await axiosFetch(apiUrl);
      let temperData = temperResp['monthlyVariance'];
      //console.log(temperData)
      //console.log(temperData[temperData.length - 1])
      //variables for svg sizing
      //const width = 1500;
      //const height = 450;
      const vertPadding = 100;
      const sidePadding = 70;

      //initial svg created
      const svg = d3.select("#heatmap-svg")
      /*
        .append("svg")
        .attr("width", width)
        .attr("height", height);*/

      //scaling set for the heat map
      const xScale = d3.scaleLinear()
        .domain([d3.min(temperData, (d) => d["year"]-1),
                d3.max(temperData, (d) => d['year'])])
        .range([sidePadding, width - sidePadding + 20])

      const yScale = d3.scaleLinear()
        .domain([d3.min(temperData, (d) => d['month'] - 0.5),
                d3.max(temperData, (d) => d['month'] + 0.5)])
        .range([25, height - vertPadding + 100])

      //set x and y axis
      const xAxis = d3.axisBottom(xScale)
        .tickFormat(d3.format('d')) //year format - used before
        .tickValues(d3.range(1760, 2015, 10))

      const yAxis = d3.axisLeft(yScale)
        .tickFormat(function(d) {
          return monthNumToName(d);
        })

      svg.append('g')
        .attr('transform', 'translate(0,' + (height - vertPadding + 100) + ')')
        .call(xAxis);

      const iniYAxis = svg.append('g')
        .attr('transform', 'translate(' + sidePadding + ', 0)')
        .call(yAxis);

      iniYAxis.selectAll('path')//.style("stroke-width", "0px");

      //tooltip
      const toolTip = d3.select("#tooltip")
        //.append("div")
        //.attr("class", "tooltip")
      
      //mouseover, move, leave logic
      const mouseOver = function(event, d) {
        toolTip
          .style("visibility", "visible")
          //.style("top", (event.clientY - 105) + "px")
          //.style("left", (event.clientX - 45) + "px")
      
        d3.select(this)
          .style("opacity", "0")
          //.style('stroke-width', '2')
          //.style("z-index", "10")
        }

      const mouseMove = function(event, d) {
        toolTip
          .html(d["year"] + " - " + monthNumToName(d['month']) + "<br>" + Math.round((d["variance"] + 8.66) * 10)/10 + "&degC<br>" + Math.round(d["variance"]*10)/10 + "&degC")        
          .style("top", (event.clientY - 100 + document.documentElement.scrollTop) + "px")
          .style("left", (event.clientX - 45) + "px")
      }

      const mouseLeave = function() {
        toolTip 
          .style("visibility", "hidden")

        d3.select(this)
          //.style('stroke-width', '0')
          .style('opacity', '1')
      }
      
      //next add in the rect values for each month
      svg.selectAll("rect")
        .data(temperData)
        .enter()
        .append("rect")
        .attr('x', (d) => xScale(d['year'] - 1))
        .attr('y', (d) => yScale(d['month'] - 0.5))
        .attr('width', 3.2)
        .attr('height', 32)
        .attr("class", "bar")
        .style('fill', (d) => {
          return variToColor(d['variance'])
        })
        .on("mouseover", mouseOver)
        .on("mousemove", mouseMove)
        .on("mouseleave", mouseLeave) 

      //legend
      //no dope text
      /*
      svg.append('text')
        .text("Monthly Global Land-Surface Temperature")
        .attr('x', width/2 - 200)
        .attr('y', 30)
        .style("font-size", "1.4em")
        .style("fill", "white")

      svg.append("text")
        .text("1753 - 2015: Base Temperature 8.66°C")
        .attr('x', width/2 - 160)
        .attr('y', 60)
        .style("font-size", "1.2em")
        .style("fill", "white")
      */
      //add the legend, which is in the form of an axis with 9 colored rectangles
      //axis first...
      const legendScale = d3.scaleLinear()
        .domain([1.8, 13.7])
        .range([width - 410, width - 60 ])

      const legendAxis = d3.axisBottom(legendScale)
        .tickValues(d3.range(2.8, 12.8, 1.1))
        .tickFormat(d3.format(".1f"))

      svg.append('g')
        .attr('transform', 'translate(' + -175 + ',' + (height - vertPadding + 165) + ')')
        .call(legendAxis);

      //next add the rectangles
      const legRectY = height + 40;
      const legRectOut = "1px solid white";
      const legRectXbase = width - 554;
      svg.append("rect")
        .attr('class', 'legendRects')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*0)//width - 379)
        .style("fill", "rgb(69, 117, 180)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*1)//width - 347)
        .style("fill", "rgb(116, 173, 209)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*2)
        .style("fill", "rgb(171, 217, 233)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*3)
        .style("fill", "rgb(224, 243, 248)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*4)
        .style("fill", "rgb(255, 255, 191)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*5)
        .style("fill", "rgb(254, 224, 144)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*6)
        .style("fill", "rgb(253, 174, 97)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')
        .style('width', '31px')
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*7)
        .style("fill", "rgb(244, 109, 67)")
        .style("outline", legRectOut)

      svg.append("rect")
        .attr('class', 'legendRects')        
        .attr("y", legRectY)
        .attr("x", legRectXbase + 32*8)
        .style("fill", "rgb(215, 48, 39)")
        .style("outline", legRectOut)
    }

    setLoading('Loaded API data!')
    drawHeatMap();
  }, );//[width, height]);

  return(
    <div id="heatmap-app">
      <div id="heatmap-chart">
        <h1>Monthly Global Land-Surface Temperature</h1>
        <h2>1753 - 2015: Base Temperature 8.66°C</h2>
        <div id="heatmap-container">
          <div id="tooltip" ></div>
          {loading === 'still loading'
            ? <p>SVG Loading...</p>
            : <svg width={width} height={height + 100} id='heatmap-svg' ref={ref} ></svg>
          }
        </div>  
      </div>
    </div>
  )
}