import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMaximize, faDownLeftAndUpRightToCenter } from '@fortawesome/free-solid-svg-icons';
import { marked  } from "https://esm.sh/marked";
import DOMPurify from "dompurify";

marked.use({
  breaks: true,
  gfm: true
});

const initialText = "# Welcome to my React Markdown Previewer!\n\n## This is a sub-heading...\n### And here's some other cool stuff:\n\nHeres some code, `<div></div>`, between 2 backticks.\n\n```\n// this is multi-line code:\n function anotherExample(firstLine, lastLine) {\n   if (firstLine == '```' && lastLine == '```') {\n     return multiLineCode;\n   }\n }\n```\n\nYou can also make text **bold**... whoa!\nOr _italic_.\nOr... wait for it... **_both!_**\nAnd feel free to go crazy ~~crossing stuff out~~.\n\nThere's also [links](https://www.freecodecamp.org), and\n> Block Quotes!\n\nAnd if you want to get really crazy, even tables:\n\nWild Header | Crazy Header | Another Header?\n------------ | ------------- | -------------\nYour content can | be here, and it | can be here....\nAnd here. | Okay. | I think we get it.\n\n- And of course there are lists.\n  - Some are bulleted.\n    - With different indentation levels.\n      - That look like this.\n\n1. And there are numbered lists too.\n1. Use just 1s if you want!\n1. And last but not least, let's not forget embedded images:\n\n![freeCodeCamp Logo](https://cdn.freecodecamp.org/testable-projects-fcc/images/fcc_secondary.svg)";

//Convert normal JS string to html
function markdownStrToHtml (string) {
  let initialHtmlStr = marked.parse(string);
  let finalHtmlStr = DOMPurify.sanitize(initialHtmlStr.slice(0));
  //let realFinalHtmlStr = noPrePreSpace(finalHtmlStr);
  return finalHtmlStr;
}

//spaces are being added after this function runs, adjusted to do nothing
//pull extra space out between any </code> and </pre> tags like </code> </pre> -> </code></pre>
/*
function noPrePreSpace (string) {
  let spaceLocArr = [];
  let preSubStr = "</pre>";
  let removedCount = 0;
  let updatedStr = "";
  //find each location of </pre> substrings
  for(let i = 0; i < string.length; i++){
    if(string.slice(i, i + preSubStr.length) == preSubStr){
      spaceLocArr.push(i)
    }
  }
  
  //now remove each of the spaces
  for(let i = spaceLocArr.length - 1; i > -1; i--){
    updatedStr = string.slice(0, spaceLocArr[i]) + string.slice(spaceLocArr[i]);
    
  }
  //console.log(updatedStr.slice(395,404))
  return updatedStr
}
*/

let mdToHtml = markdownStrToHtml(initialText);

//React
class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  
  render() {
      
    return (
      <div id="editorRow" className="row" >
        <div className="empty col-2 col-sm-3 col-xxl-4" ></div>
        
        <div id="editorContainer" className="col-8 col-sm-6 col-xxl-4" style={this.props.editorShow}  >
          <div id="editorHead">
            <p id="editHeadText" >Editor</p>
            <FontAwesomeIcon icon={this.props.expButton} onClick={this.props.editorClick} />
            {/*
            <i 
              className={this.props.expButton}
              onClick={this.props.editorClick}
              >
              
            </i>
          */}
          </div>
          <textarea 
            id="editor" 
            value={this.props.input} 
            onChange={this.props.handleChange}
            style={
              this.props.editorStyle
            }
          />
          
        </div>
        
        <div className="empty col-2 col-sm-3 col-xxl-4 " ></div>
      </div>
    )
  }
}

class Previewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
            
    }
  }
  
  render() {
    
    return (
      <div id="previewRow" className="row" >
        <div className="empty col-1 col-sm-2 col-xxl-3" ></div>
        
        <div id="previewContainer" 
          className="col-10 col-sm-8 col-xxl-6" 
          style={this.props.previewerShow} 
          >
          <div id="previewHead">
            <p id="prevHeadText" >Preview</p>
            <FontAwesomeIcon icon={this.props.expButton} onClick={this.props.previewerClick} />
            {/*
            <i 
              className={this.props.expButton}
              onClick={this.props.previewerClick}
              ></i>
            */}
          </div>
          <div 
            id="preview" 
            dangerouslySetInnerHTML={{__html: markdownStrToHtml(this.props.output)}}
            style={this.props.previewerStyle}
            >
          </div>
 
        </div>
        
        <div className="empty col-1 col-sm-2 col-xxl-3" ></div>
      </div>
    )
  }
}

export default class MarkdownPreviewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: initialText,
      previewCode: mdToHtml,
      expButton: faMaximize,//"fa-solid fa-maximize",
      editorMinHeight: "200px",
      previewMinHeight: "150px",
      displayEditor: "",
      displayPreviewer: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.editorClick = this.editorClick.bind(this);
    this.previewerClick = this.previewerClick.bind(this);
  }
    
  handleChange(event) {
    this.setState({
      inputValue: event.target.value,
      previewCode: markdownStrToHtml(event.target.value)
    });
  }
  
  editorClick() {
    if(this.state.displayPreviewer === "") {
      this.setState({
        editorMinHeight: "800px",
        expButton: faDownLeftAndUpRightToCenter,//"fa-solid fa-down-left-and-up-right-to-center",
        displayPreviewer: "none"
      })
    } else {
      this.setState({
        editorMinHeight: "200px",
        expButton: faMaximize,//"fa-solid fa-maximize",
        displayPreviewer: ""
      })
    }
  }
  
  previewerClick() {
    if(this.state.displayEditor === "") {
      this.setState({
        previewMinHeight: "800px",
        expButton: faDownLeftAndUpRightToCenter,//"fa-solid fa-down-left-and-up-right-to-center",
        displayEditor: "none"
      })
    } else {
      this.setState({
        previewMinHeight: "200px",
        expButton: faMaximize,//"fa-solid fa-maximize",
        displayEditor: ""
      })
    }
  }
  
 
  
  render() {
    
    let editorStyle = {
      minHeight: this.state.editorMinHeight
    }
    
    let editorShow = {
      display: this.state.displayEditor
    }

    let previewerStyle = {
      minHeight: this.state.previewMinHeight
    }
    
    let previewerShow = {
      display: this.state.displayPreviewer
    }
    
    return (
      <div id="markdown-container" className="container-fluid">
        <Editor 
          input={this.state.inputValue} 
          handleChange={this.handleChange}
          editorClick={this.editorClick}
          expButton={this.state.expButton} 
          editorStyle={editorStyle}
          editorShow={editorShow}
        />
        <Previewer 
          output={this.state.previewCode}
          previewerClick={this.previewerClick}
          expButton={this.state.expButton} 
          previewerStyle={previewerStyle}
          previewerShow={previewerShow}
        />
        
      </div>
    )
  }
}

//ReactDOM.render(<App />, document.querySelector('#reactApp'))

/*
//jQuery
  //either used to hide one of the modules when clicked, or to live populate preview
$(window).on("load",function() {
  //$("#preview pre code").css("border", "1px solid black")
  //code below carves out the extra space between the marked.js generated </code> and 
  //</pre> tags (comes out </code> </pre> instead of </code></pre> and adds a line unnecessarily in code box.  
  //seems its coming out of marked.js without a space but space is added when injecting code into preview
  let content = $('#preview pre').text();
  let newContent = content.substring(0, content.length - 1);
  $("#preview pre").text(newContent);
});
*/