import { combineReducers } from 'redux';
import { sessionLength, breakLength, timerMinutes, timerSeconds, playStatus, playSafe, breakStatus } from './twenty-five/twenty-five-reducers'
import { previousInputs, inputDisplay } from './calculator/calculator-reducers'
import { displayMsg, bankLoc, playLinks } from './drumapp/drum-reducers'
import { displayComponent } from './App-reducers'

const rootReducer = combineReducers({
    //app.js reducer(s)
    displayComponent,
    //twentyfiveapp.jsx reducers
    sessionLength,
    breakLength,
    timerMinutes,
    timerSeconds,
    playStatus,
    playSafe,
    breakStatus,
    //calculator.jsx reducers
    previousInputs,
    inputDisplay,
    //drum app reducers
    displayMsg,
    bankLoc,
    playLinks,
  });
  
  export default rootReducer;