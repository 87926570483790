import React from 'react';
import { connect } from 'react-redux';
import { chgBank, newLetter,powerOff, chgVol } from './drum-actions.js';
//import { connect } from 'react-redux';

class DrumComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoad: 'tbd',
      initialDisp: "Power On, ",
      powerStatus: "On",
      pwrSwitchColor: "white",
      pwrSwitchLoc: "right",
      volumeLvl: 5
    };
    this.powerSwitch = this.powerSwitch.bind(this);
    this.bankSwitch = this.bankSwitch.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.pressCheck = this.pressCheck.bind(this);
    this.handleQClick = this.handleQClick.bind(this);
    this.handleWClick = this.handleWClick.bind(this);
    this.handleEClick = this.handleEClick.bind(this);
    this.handleAClick = this.handleAClick.bind(this);
    this.handleSClick = this.handleSClick.bind(this);
    this.handleDClick = this.handleDClick.bind(this);
    this.handleZClick = this.handleZClick.bind(this);
    this.handleXClick = this.handleXClick.bind(this);
    this.handleCClick = this.handleCClick.bind(this);
    this.adjustVolume = this.adjustVolume.bind(this);
  }
 
  componentDidMount(){
    if(this.state.initialLoad === 'tbd'){
      setTimeout(() => {
        this.setState(state => ({
          initialLoad: 'done',
          initialDisp: ""
        }));
      }, 1000);
    }
    this.props.changeToBank("Heater Kit");
    document.addEventListener("keyup", this.pressCheck);
    let volumeSlider = document.getElementById("volumeSlider");
    volumeSlider.addEventListener("input", this.adjustVolume, this.playAudio);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.pressCheck)
    document.removeEventListener("input", this.adjustVolume, this.playAudio);
  }

  powerSwitch() {
    if(this.state.powerStatus === "On"){
      this.setState(state => ({
        powerStatus: "Off",
        pwrSwitchColor: "hsl(24, 70%, 50%)",
        pwrSwitchLoc: "left"
      }));
      //call action creator to wipe display
      this.props.powerOffDisp("");
    } else {
      this.setState(state => ({
        powerStatus: "On",
        pwrSwitchColor: "white",
        pwrSwitchLoc: "right"
      }));
      
    }
  }
  
  
  bankSwitch() {
    if(this.state.powerStatus === "On"){
      if(this.props.rootReducer.bankLoc === "left"){
        this.props.changeToBank("Smooth Piano Kit");  
        
      } else if(this.props.rootReducer.bankLoc === "right"){
        this.props.changeToBank("Heater Kit");
        
      } else {
        this.props.changeToBank("Smooth Piano Kit");
      };
    }
  }

  //getLetterLink(letter)
  pressCheck(event) {
    switch(event.key) {
      case "q" || 'Q':
        this.playAudio('Q');
        break
      case 'w' || 'W':
        this.playAudio('W');
        break
      case "e" || 'E':
        this.playAudio('E');
        break
      case 'a' || 'A':
        this.playAudio('A');
        break
      case "s" || 'S':
        this.playAudio('S');
        break
      case 'd' || 'D':
        this.playAudio('D');
        break
      case "z" || 'Z':
        this.playAudio('Z');
        break
      case 'x' || 'X':
        this.playAudio('X');
        break
      case 'c' || 'C':
        this.playAudio('C');
        break
      default:
        console.log("not valid key") ; 
    }
  }
  
  handleQClick() {
    this.playAudio("Q");
  }

  handleWClick() {
    this.playAudio("W");
  }

  handleEClick() {
    this.playAudio("E");
  }

  handleAClick() {
    this.playAudio("A");
  }

  handleSClick() {
    this.playAudio("S");
  }

  handleDClick() {
    this.playAudio("D");
  }

  handleZClick() {
    this.playAudio("Z");
  }

  handleXClick() {
    this.playAudio("X");
  }

  handleCClick() {
    this.playAudio("C");
  }

  adjustVolume(event){
    //id="volumeSlider
    const volumeValue = Number(event.target.value);
    //console.log("volumeValue: " + volumeValue);
    this.setState(state =>({
      volumeLvl: volumeValue
    }));
    this.props.changeVolume(Number(event.target.value));
        
    setTimeout(() => {
        this.props.powerOffDisp("");
    }, 1000);
  }
  
  playAudio(letter, event) {
    if(this.state.powerStatus === "On"){
      let bankLetter = letter;
      if(this.props.rootReducer.bankLoc === ""){
        bankLetter = "heater" + letter;
      } else if(this.props.rootReducer.bankLoc === "left"){
        bankLetter = "heater" + letter;
      } else if(this.props.rootReducer.bankLoc === "right"){
        bankLetter = "piano" + letter;
      }
      
      this.props.getLetterName(bankLetter);
      
      let testAudioLoc = document.getElementById(letter);
      let cloneAudio = testAudioLoc.cloneNode();
      cloneAudio.volume = this.state.volumeLvl/10;
      //doesnt work, getting corrupted data from slider from event cloneAudio.volume = this.props.rootReducer.volumeLevel/10;
      cloneAudio.play();
    }
  }

  

  render() {
    let pwrSwitchLoc = {justifyContent: this.state.pwrSwitchLoc};

    let pwrSwitchColor = {backgroundColor: this.state.pwrSwitchColor};

    let bankSwitchLoc = {justifyContent: this.props.rootReducer.bankLoc};
    let displayMessage = this.state.initialDisp
    
    if(this.state.powerStatus === "On"){
      displayMessage = this.state.initialDisp 
 + this.props.rootReducer.displayMsg;
    } else {
      displayMessage = this.state.initialDisp;
    };
    
    //console.log(store.getState())
    //console.log(this.props.rootReducer.playLinks.Q)
    
    return (
      <div id="drum-container" className="container-fluid">
        <div id="drum-machine">
          <div id="allDrumPads">
            <div className="padRow">
              <div 
                className="drum-pad"
                onClick={this.handleQClick}
                >
                <audio
                  className="clip" 
                  id="Q" 
                  src={this.props.rootReducer.playLinks.Q}
                  >
                </audio>
                Q</div>
              <div className="drum-pad" onClick={this.handleWClick}>
                <audio
                  className="clip" 
                  id="W" 
                  src={this.props.rootReducer.playLinks.W} >
                </audio>W</div>
              <div className="drum-pad" onClick={this.handleEClick}>
                <audio
                  className="clip" 
                  id="E" 
                  src={this.props.rootReducer.playLinks.E} >
                </audio>E</div>
            </div>
            <div className="padRow">
              <div className="drum-pad" onClick={this.handleAClick}>
                <audio
                  className="clip" 
                  id="A" 
                  src={this.props.rootReducer.playLinks.A} >
                </audio>A</div>
              <div className="drum-pad" onClick={this.handleSClick}>
                <audio
                  className="clip" 
                  id="S" 
                  src={this.props.rootReducer.playLinks.S} >
                </audio>S</div>
              <div className="drum-pad" onClick={this.handleDClick}>
                <audio
                  className="clip" 
                  id="D" 
                  src={this.props.rootReducer.playLinks.D} >
                </audio>D</div>
            </div>
            <div className="padRow">
              <div className="drum-pad" onClick={this.handleZClick}>
                <audio
                  className="clip" 
                  id="Z" 
                  src={this.props.rootReducer.playLinks.Z} >
                </audio>Z</div>
              <div className="drum-pad" onClick={this.handleXClick}>
                <audio
                  className="clip" 
                  id="X" 
                  src={this.props.rootReducer.playLinks.X} >
                </audio>X</div>
              <div className="drum-pad" onClick={this.handleCClick}>
                <audio
                  className="clip" 
                  id="C" 
                  src={this.props.rootReducer.playLinks.C} >
                </audio>C</div>
            </div>
          </div>
          <div id="ctrlDispContainer">
            <div className="pwrBankCtrl">
              <p className="switchLabel" >Power</p>
              <div className="switchBack" style={pwrSwitchLoc} >
                <div className="switch" onClick={this.powerSwitch} style={pwrSwitchColor} ></div>
              </div>
            </div>
            <div id="display">
              <p id="dispText" >{displayMessage}</p>
            </div>
            <div id="volumeCtrl">
              <input id="volumeSlider" type="range" max="10" min="0" step=
                "0.1" defaultValue={this.state.volumeLvl} ></input>
            </div>
            <div className="pwrBankCtrl">
              <p className="switchLabel" >Bank</p>
              <div className="switchBack" style={bankSwitchLoc} >
                <div className="switch" onClick={this.bankSwitch} ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootReducer: {
      displayMsg: state.displayMsg,
      bankLoc: state.bankLoc,
      playLinks: state.playLinks
    }
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeToBank: (newBank) => 
      dispatch(chgBank(newBank)),      
    getLetterName: (bankLetter) => 
      dispatch(newLetter(bankLetter)),
    powerOffDisp: (powerStatus) => 
      dispatch(powerOff(powerStatus)),
    changeVolume: (volumeValue) =>
      dispatch(chgVol(volumeValue))
  }
}

const DrumApp = connect(mapStateToProps, mapDispatchToProps)(DrumComp);

export default DrumApp;